export function setGsContent(gsType) {
  const gsText = {
    1: `
      - Demonstrate a deep understanding of Indian art, literature, and cultural traditions.
      - Accurately discuss key historical events, personalities, and their impact.
      - Display knowledge of physical and human geography concepts.
      - Relate cultural, historical, and geographical factors to current issues.
      - Present ideas and arguments with clarity and coherence.
    `,
    2: `
      - Showcase understanding of the Indian Constitution and its key features.
      - Exhibit knowledge of governance structures, policies, and initiatives.
      - Demonstrate grasp of social justice issues and welfare schemes.
      - Display awareness of international relations and India's foreign policy.
      - Critically analyze governance and policy issues.
    `,
    3: `
      - Exhibit knowledge of technology and its applications in various sectors.
      - Demonstrate understanding of economic development concepts and policies.
      - Show awareness of biodiversity, environmental challenges, and conservation efforts.
      - Display grasp of internal and external security threats and disaster management strategies.
      - Analyze the interconnectedness of technology, economy, environment, and security.
    `,
    4: `
      - Demonstrate understanding of ethical concepts, theories, and their application in governance.
      - Exhibit knowledge of the role of ethics and integrity in public service.
      - Identify and resolve ethical dilemmas in administrative situations.
      - Show awareness of the importance of emotional intelligence and moral leadership.
      - Present ethical arguments and decision-making processes with clarity and coherence.
    `,
  };

  return gsText[gsType];
}
