import React, { useState, useEffect } from "react";
import axios from "axios";
import "./style.css";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { CircularProgress, TablePagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../api/client";
import { examList } from "../grade/filter";
import { toast } from "react-toastify";
import moment from "moment/moment";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Essays() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const roleType = JSON.parse(localStorage.getItem("userDetails"));
  const [essayData, setEssayData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const currentDate = moment(new Date()).toISOString();
    if (currentDate > roleType?.expiry) {
      localStorage.removeItem("userDetails");
      toast.error("session expired");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    let endPonits = `teacherid=${roleType?.loginData?.id}`;
    if (roleType.loginData.role === "Student") {
      endPonits = `teacherid=${roleType.loginData?.teachers_id}&studentid=${roleType.loginData?.id}`;
    }
    axios
      .get(`${BASE_URL}/getessays/?${endPonits}`)
      .then((res) => {
        const respo = res?.status === 200;
        if (respo) {
          const filteredData = res?.data?.essays
            .filter((data) =>
              !data.isreal ? "" : data?.regenjson?.content ? "" : data
            )
            .sort((a, b) => {
              return a.id - b.id;
            });
          setEssayData(filteredData);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("error in axios", err);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleGradeNewEssay = () => {
    navigate("/studentGrade");
  };

  const handleViewGrade = (e, id, row) => {
    navigate(`/regen_grade/${id}`, { state: { data: row } });
  };

  function compareArrays(essayValue) {
    let matchingItem = examList?.find((item) => item.value === essayValue);
    return matchingItem ? matchingItem?.lable : "No topic";
  }

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.trim());
    setPage(0);
  };

  const searchData =
    searchQuery !== ""
      ? essayData?.filter((row) =>
          String(row?.id).toLowerCase().includes(searchQuery.toLowerCase())
        )
      : essayData;

  return (
    <div style={{ padding: "40px 20px" }}>
      <Grid container spacing={2}>
        <Grid item sx={12} md={6} lg={6}>
          <div>
            <h5 className="page-name">Graded Copies</h5>
          </div>
        </Grid>
        <Grid item sx={12} md={6} lg={6}>
          <div className="search-parent-div">
            <div className="search-div">
              <TextField
                className="search-field"
                placeholder={"Search"}
                type={"text"}
                onChange={(e) => handleSearch(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ cursor: "pointer", marginLeft: "0px" }}
                    >
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            {roleType?.loginData?.role === "teacher" && (
              <div className="grade-new-essay-btn-div">
                <Button
                  variant="contained"
                  onClick={handleGradeNewEssay}
                  className="grade-new-essay-btn"
                >
                  Grade New Essay
                </Button>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      {loading ? (
        <div className="loder-div-styles">
          <CircularProgress size={50} />
        </div>
      ) : (
        <>
          <TableContainer component={Paper} style={{ marginTop: "25px" }}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell className="essay-TH-cell1">
                    S. NO.
                  </StyledTableCell>
                  <StyledTableCell className="essay-TH-cell2">
                    ESSAY ID
                  </StyledTableCell>
                  <StyledTableCell className="essay-TH-cell2">
                    ACTION
                  </StyledTableCell>
                  {/* <StyledTableCell className="essay-TH-cell3">
                    STUDENT
                  </StyledTableCell> */}
                  <StyledTableCell className="essay-TH-cell4">
                    TOPIC
                  </StyledTableCell>
                  {/* <StyledTableCell className="essay-TH-cell5">
                    MARKS
                  </StyledTableCell> */}
                  <StyledTableCell className="essay-TH-cell6">
                    FILE NAME
                  </StyledTableCell>
                  <StyledTableCell className="essay-TH-cell7">
                    FORMAT
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {searchData && searchData.length > 0 ? (
                  searchData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((row, index) => (
                      <StyledTableRow key={page * rowsPerPage + index}>
                        <StyledTableCell align="center">
                          {page * rowsPerPage + index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.id}
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={(e) =>
                            handleViewGrade(e, row?.id, row?.regenjson)
                          }
                          className="view-profile-tag"
                        >
                          View Grade
                        </StyledTableCell>
                        {/* <StyledTableCell>
                          {row?.student?.first_name} {row?.student?.last_name}
                        </StyledTableCell> */}
                        <StyledTableCell>
                          {compareArrays(row?.exam)}
                        </StyledTableCell>
                        {/* <StyledTableCell align="center">
                          {row?.marks}
                        </StyledTableCell> */}
                        <StyledTableCell>
                          <a
                            target="_blank"
                            className="view-profile-tag"
                            rel="noreferrer"
                            href={row?.file}
                            download={row?.file}
                          >
                            {row?.file}
                          </a>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <PictureAsPdfIcon />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={8} align="center">
                      No Data Found
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {searchData.length > 10 && (
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={searchData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Essays;
