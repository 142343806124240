import React, { useEffect, useState } from "react";

import "./UserFeedback.css"; // Import your CSS file
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL, DEV_BASE_URL } from "../api/client";

const UserFeedback = ({ setSfbModal }) => {
  let roleType = JSON.parse(localStorage.getItem("userDetails"));
  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState(null);
  // const [show, setShow] = useState(true);

  // const showFb = async () => {
  //   try {
  //     const res = await axios.get(
  //       `${DEV_BASE_URL}/studentFeedback/has_rated/${roleType?.loginData?.id}`
  //     );
  //     setShow(res?.data?.hasRated);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   showFb();
  // }, []);

  const handleSubmit = async () => {
    // Handle form submission logic here
    console.log("Feedback:", feedback);
    console.log("Rating:", rating);
    setSfbModal(false);
    const formData = {
      feedback: feedback,
      student_id: roleType?.loginData?.id,
      rating: parseInt(rating),
    };
    try {
      const res = await axios.post(
        `${DEV_BASE_URL}/studentFeedback/submit_website_rating/`,
        formData,
        {
          headers: {
            "Content-Type": "Application/json",
          },
        }
      );

      toast.success("Thanks for the feedback");
      console.log(res);
      return res;
    } catch (error) {
      console.error("Error  submitting feedback", error);
      toast.error("Something went wrong");
      throw error;
    }
  };

  return (
    <div>
      <div className="feedback-container">
        <h2>Please provide your feedback</h2>
        <div className="rating-container">
          <p>How likely are you to recommend our platform to others?</p>
          <div className="rating">
            {[1, 2, 3, 4, 5].map((num) => (
              <button
                key={num}
                onClick={() => setRating(num)}
                className={rating === num ? "active" : ""}
              >
                {num}
              </button>
            ))}
          </div>
        </div>
        <textarea
          className="feedback-textarea"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="Your feedback here..."
          style={{
            marginTop: 16,
          }}
        />
        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <Button
            className="login-button"
            onClick={handleSubmit}
            id="gradeButton"
            style={{ maxWidth: 400 }}
          >
            Submit Feedback
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserFeedback;
