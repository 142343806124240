/* eslint-disable jsx-a11y/alt-text */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { DEV_BASE_URL } from "../../../api/client";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import UploadIcon from "../../../assets/images/upload.svg";
import coin from "../../../assets/images/coin.svg";
import cross from "../../../assets/images/cross.svg";
import info from "../../../assets/images/info.svg";
import LogoutIcon from "@mui/icons-material/Logout";
import Logo from "../../../assets/images/logo.svg";
import rightArrow from "../../../assets/images/right-arrow.svg";
import { toast } from "react-toastify";
import { PDFDocument } from "pdf-lib";
import InfoToolTip from "../../InfoToolTip";

export const Header = ({ value, setValue }) => {
  const navigate = useNavigate();
  const [openLogoutBox, setOpenLogoutBox] = useState(false);
  const [loading, setLoading] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLogout = () => {
    setOpenLogoutBox(false);
    toast.success("Logged out successfully");
    setTimeout(() => {
      localStorage.removeItem("userDetails");
      navigate("/");
    }, 500);
  };

  const handleLogoutConfirmClose = () => {
    setOpenLogoutBox(false);
  };

  const handleLogoutConfirmOpen = () => {
    setOpenLogoutBox(true);
  };

  return (
    <div>
      <Grid item md={12} lg={12} sm={12}>
        <div className="logo-back-parent-div">
          <div>
            <img src={Logo} alt="Logo" />
          </div>
          {windowWidth > 1200 && <h2>Grade New Answer</h2>}
          <div
            className="logout-go-to-essay-partent-div"
            style={{ columnGap: 20 }}
          >
            <button
              // className="feedback-page-GoToEssay"
              style={{
                borderColor: "#B9B9B9",
                borderRadius: 200,
                padding: "8px 12px",
                backgroundColor: "white",
                borderStyle: "solid",
                display: "flex",
                alignItems: "center",
                columnGap: 8,
                color: "#6374FA",
              }}
              onClick={() => navigate("/graded/copies")}
            >
              Go to Graded Copies{" "}
              <span>
                <img src={rightArrow} />
              </span>
            </button>
            <button
              style={{
                borderColor: "#B9B9B9",
                borderRadius: 200,
                padding: "8px 12px",
                backgroundColor: "white",
                borderStyle: "solid",
                display: "flex",
                alignItems: "center",
                columnGap: 8,
              }}
            >
              <img src={coin} />
              <span style={{ color: "#2F6545" }}>{value} Credits</span>
            </button>
            <Button
              variant="outlined"
              className="grade-page-logout-btn"
              onClick={handleLogoutConfirmOpen}
            >
              <LogoutIcon style={{ fontSize: "20px" }} /> Logout
            </Button>
          </div>
        </div>
        <Dialog
          open={openLogoutBox}
          onClose={handleLogoutConfirmClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to logout?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLogoutConfirmClose}>No</Button>
            <Button onClick={handleLogout} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  );
};

const Page1 = () => {
  const navigate = useNavigate();
  let roleType = JSON.parse(localStorage.getItem("userDetails"));
  const [value, setValue] = useState(20);
  const answerFileInputRef = useRef(null);
  const [answerFile, setAnswerFile] = useState(null);
  const [answerFileName, setAnswerFileName] = useState(null);
  const [isHandwritten, setIsHandwritten] = useState("0");
  const [gsType, setGsType] = useState("1");
  const [numQuestions, setNumQuestions] = useState(1);
  const [questions, setQuestions] = useState([]);
  const [maxWordLimit, setMaxWordLimit] = useState(0);
  const [gradingInstructions, setGradingInstructions] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setshow] = useState(true);
  const [hoverIndex, setHoverIndex] = useState(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5, // Set the maxHeight to 4.5 times the item height (48)
        width: 250,
        marginTop: 40,
      },
    },
  };

  const getCredits = async () => {
    try {
      const res = await axios.get(
        `${DEV_BASE_URL}/studentFeedback/get_student_credits/${roleType?.loginData?.id}/`
      );
      if (parseInt(res?.data?.total_questions_graded) > 20) {
        setValue(0);
      } else {
        setValue(20 - parseInt(res?.data?.total_questions_graded));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCredits();
  }, []);

  const handleAnswerFileDrop = (e) => {
    e.preventDefault();
    const selectedFile = e.dataTransfer.files[0];
    handleFileUpload(selectedFile, "answer");
  };

  const handleRemoveFile = (type) => {
    setAnswerFile(null);
    setAnswerFileName(null);
  };

  const handleFileUpload = async (selectedFile, type) => {
    localStorage.removeItem("tableState");
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const buffer = e.target.result;
        const pdfDoc = await PDFDocument.load(new Uint8Array(buffer));
        const numPages = pdfDoc.getPageCount();
        console.log("My file", selectedFile);
      };
      reader.readAsArrayBuffer(selectedFile);

      setAnswerFile(selectedFile);
      setAnswerFileName(selectedFile.name);
    } else {
      console.error("No file selected");
    }
  };

  const handleFileInputChange = (e, type) => {
    const selectedFile = e.target.files[0];
    handleFileUpload(selectedFile, type);
  };

  const handleAnswerFileClick = () => {
    answerFileInputRef.current.click();
  };

  const handleGsTypeChange = (event) => {
    setGsType(event.target.value);
  };

  const handleNumQuestionsChange = (e) => {
    if (e.target.value === 1) setshow(true);
    setNumQuestions(e.target.value);
    if (e.target.value > 1) {
      setshow(false);
      setQuestions(
        new Array(e.target.value).fill({
          questionText: "",
          pageNumber: "",
          wordLimit: "",
          gradingInstructions: "",
        })
      );
    } else {
      setQuestions([]);
    }
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = questions.map((q, i) => {
      if (i === index) {
        return { ...q, [field]: value };
      }
      return q;
    });
    setQuestions(updatedQuestions);
  };

  function convertToFormat(data, index) {
    return [
      index, // question index (index of array)
      data.gradingInstructions,
      "",
      -1,
      data.wordLimit ? parseInt(data.wordLimit) : -1,
      data.questionText,
    ];
  }

  function transformPageNumberArray(arr) {
    const result = [];

    for (let i = 0; i < arr.length; i += 2) {
      const index = arr[i];
      const pages = arr[i + 1];

      // Convert the page string to an array of numbers
      const pageNumbers = pages ? pages.split(",").map(Number) : [];

      // Determine start and end page
      const startPage = pageNumbers.length > 0 ? pageNumbers[0] - 1 : -1;
      const endPage =
        pageNumbers.length > 1 ? pageNumbers[pageNumbers.length - 1] - 1 : -1;

      // Push the result
      result.push([index, startPage, endPage]);
    }

    return result;
  }

  const handleGradeButtonClick = async () => {
    if (value === 0) {
      toast.error("Looks like you have exhausted all your credits");
      return;
    }
    const data = {
      teacher: roleType?.loginData?.teachers_id,
      student: roleType?.loginData?.id,
      file: answerFile,
      paperType: 1,
      handwritten: 0,
      numQuestions: 2,
      pageNumbers: transformPageNumberArray(pageNumbers),
      hasOverview: 0,
      overviewAtStart: -1,
      Layout: "",
      moreInfo: questions.map((data, index) => convertToFormat(data, index)),
    };
    console.log("<<<<<", data);
    handleGrading(answerFile, data);
  };

  let pageNumbers = [];
  questions.forEach((q, idx) => {
    pageNumbers.push(idx, q.pageNumber);
  });

  const handleGrading = async (file, data) => {
    if (!file) {
      console.error("No file uploaded");
      toast.error("Please upload a PDF");
      return;
    }

    let res;
    setLoading(true);
    try {
      const formData = new FormData();

      formData.append("teacher", roleType?.loginData?.teachers_id);
      formData.append("student", roleType?.loginData?.id);
      formData.append("paperType", gsType);
      formData.append("file", file);
      formData.append("handwritten", isHandwritten);
      formData.append("numQuestions", numQuestions === 1 ? -1 : numQuestions);
      formData.append("pageNumbers", JSON.stringify(data.pageNumbers));
      formData.append("hasOverview", 0);
      formData.append("overviewAtStart", -1);
      formData.append("Layout", JSON.stringify(""));
      formData.append("moreInfo", JSON.stringify(data.moreInfo));

      console.log("fff", formData);

      res = await axios.post(`${DEV_BASE_URL}/apiview/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Grade essay successfully");
      console.log(res);
      navigate("/student/answer/feedback", {
        state: { data: res.data.data_json },
      });
      return res;
    } catch (err) {
      console.error("Error in processing students:", err.response || err);
      toast.error("Grade essay failed");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Header value={value} setValue={setValue} />
      {loading && (
        <div className="grade-page-loader">
          <CircularProgress />
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: windowWidth < 1200 ? 60 : 180,
          paddingRight: windowWidth < 1200 ? 60 : 180,
        }}
      >
        <div style={{ display: "flex", columnGap: 60, alignItems: "center" }}>
          <>
            {answerFileName ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: 24,
                  padding: "20px 64px",
                  backgroundColor: "#E4FFEF",
                  boxShadow: "0px 36px 40px 0px #193A561F",
                  justifyContent: "center",
                  height: 330,
                  marginTop: 50,
                  border: "2px solid",
                  borderImageSource:
                    "linear-gradient(325.34deg, #92A1FF -5.53%, #FFFFFF 82.55%)",
                }}
              >
                <div
                  style={{
                    alignSelf: "flex-end",
                    cursor: "pointer",
                    color: "#A2A2A2",
                    fontSize: 20,
                    fontWeight: "600",
                  }}
                  onClick={() => handleRemoveFile("answer")}
                >
                  <img src={cross} />
                </div>
                <p>File Uploaded</p>
                <img src={UploadIcon} />
                <p>
                  {answerFileName.length > 30
                    ? answerFileName.substring(0, 27) + "..."
                    : answerFileName}
                </p>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: 24,
                  padding: "20px 64px",
                  backgroundColor: "#F4FAFF",
                  boxShadow: "0px 36px 40px 0px #193A561F",
                  justifyContent: "center",
                  height: 330,
                  marginTop: 50,
                  border: "2px solid",
                  borderImageSource:
                    "linear-gradient(325.34deg, #92A1FF -5.53%, #FFFFFF 82.55%)",
                }}
                onDrop={handleAnswerFileDrop}
                onDragOver={(e) => e.preventDefault()}
              >
                <button
                  style={{
                    backgroundColor: "#E1EBFF",
                    padding: "18px 48px",
                    borderRadius: 200,
                    color: "#3C45CD",
                    border: "none",
                    fontWeight: "bold",
                  }}
                  onClick={handleAnswerFileClick}
                >
                  Upload PDF
                </button>
                <p style={{ color: "#747474" }}>or</p>
                <div>
                  <p
                    style={{
                      color: "#747474",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Drag & Drop your file here
                  </p>
                  <p style={{ fontSize: 12, color: "#747474" }}>
                    10mb max file size (Only .PDF supported)
                  </p>
                  <input
                    type="file"
                    ref={answerFileInputRef}
                    style={{ display: "none" }}
                    onChange={(e) => handleFileInputChange(e, "answer")}
                    accept=".pdf"
                  />
                </div>
              </div>
            )}
          </>
          <div>
            <h4 style={{ color: "#747474" }}>UPLOAD INSTRUCTIONS</h4>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: windowWidth < 1200 ? "8px" : "8px 12px",
                borderRadius: 8,
                borderStyle: "solid",
                borderColor: "#E8E8E8",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", columnGap: 16 }}
              >
                <p style={{ color: "#A2A2A2", fontSize: 20 }}>1</p>
                <p style={{ color: "#070519" }}>
                  Don't include any overview page in the PDF.
                </p>
              </div>
              <button
                style={{
                  borderColor: "#B9B9B9",
                  borderRadius: 200,
                  padding: "8px 12px",
                  backgroundColor: "white",
                  cursor: "pointer",
                  borderStyle: "solid",
                }}
                onClick={() =>
                  window.open(
                    "https://ajeei.s3.ap-south-1.amazonaws.com/sampleTemplate.png"
                  )
                }
              >
                What is an overview page?
              </button>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: 24,
                padding: "8px 12px",
                borderRadius: 8,
                borderStyle: "solid",
                borderColor: "#E8E8E8",
                marginTop: 8,
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", columnGap: 16 }}
              >
                <p style={{ color: "#A2A2A2", fontSize: 20 }}>2</p>
                <p style={{ color: "#070519" }}>
                Upload only one question in the PDF.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "8px 12px",
                borderRadius: 8,
                borderStyle: "solid",
                borderColor: "#E8E8E8",
                marginTop: 8,
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", columnGap: 16, marginRight: 100 }}
              >
                <p style={{ color: "#A2A2A2", fontSize: 20 }}>3</p>
                <p style={{ color: "#070519", maxWidth: 500 }}>
                  Make sure answer has the question written in the
                  beginning, at the top of the page
                </p>
              </div>
              <button
                style={{
                  borderColor: "#B9B9B9",
                  borderRadius: 200,
                  padding: "8px 12px",
                  backgroundColor: "white",
                  cursor: "pointer",
                  borderStyle: "solid",
                }}
                onClick={() =>
                  window.open(
                    "https://ajeei.s3.ap-south-1.amazonaws.com/upsc-grader-b2c-sample-printed.pdf"
                  )
                }
              >
                View Example
              </button>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 12px",
                borderRadius: 8,
                borderStyle: "solid",
                borderColor: "#E8E8E8",
                marginTop: 8,
                columnGap: 16,
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", columnGap: 16 }}
              >
                <p style={{ color: "#A2A2A2" }}>4</p>
                <p style={{ color: "#070519" }}>Each question grading costs</p>
              </div>
              <button
                style={{
                  borderColor: "#B9B9B9",
                  borderRadius: 200,
                  padding: "8px 12px",
                  backgroundColor: "white",
                  cursor: "pointer",
                  borderStyle: "solid",
                  display: "flex",
                  alignItems: "center",
                  columnGap: 8,
                }}
              >
                <img src={coin} />
                <span style={{ color: "#2F6545" }}>1 Credit</span>
              </button>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: 20,
            marginTop: 32,
            marginBottom: 32,
          }}
        >
          <p>
            Is the{" "}
            <span style={{ fontWeight: "bold" }}>Question handwritten</span>?
          </p>
          <div style={{ columnGap: 14, display: "flex" }}>
            <label>
              <input
                type="radio"
                value="1"
                name="handwritten"
                onChange={(e) => setIsHandwritten(e.target.value)}
              />{" "}
              Yes
            </label>
            <label>
              <input
                type="radio"
                value="0"
                name="handwritten"
                defaultChecked
                onChange={(e) => setIsHandwritten(e.target.value)}
              />{" "}
              No
            </label>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: 60,
            marginTop: -20,
          }}
        >
          <div>
            <InputLabel style={{ color: "#464646", fontWeight: "600" }}>Paper Type</InputLabel>
            <FormControl fullWidth style={{ minWidth: 560 }}>
              <Select value={gsType} onChange={handleGsTypeChange}>
                <MenuItem value="1">GS-1</MenuItem>
                <MenuItem value="2">GS-2</MenuItem>
                <MenuItem value="3">GS-3</MenuItem>
                <MenuItem value="4">GS-4</MenuItem>
                <MenuItem value="5">Others</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <InputLabel style={{ color: "#464646", fontWeight: "600" }}>Maximum Word Limit (Optional)</InputLabel>
            <TextField
              placeholder="Enter word limit"
              type="number"
              onChange={(e) => setMaxWordLimit(e.target.value)}
              style={{ minWidth: 560 }}
            />
          </div>
          {/* <div>
            <InputLabel>Number of Questions</InputLabel>
            <FormControl
              fullWidth
              style={{ minWidth: windowWidth < 1200 ? 60 : 400 }}
            >
              <Select
                value={numQuestions}
                onChange={handleNumQuestionsChange}
                MenuProps={menuProps}
              >
                {Array(20)
                  .fill()
                  .map((_el, idx) => (
                    <MenuItem value={idx + 1}>{idx + 1}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div> */}
        </div>
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            display: "flex",
            flexDirection: "column",
            rowGap: 20,
          }}
        >
          {/* {questions.map((question, index) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: 24,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  rowGap: 8,
                }}
              >
                <div
                  style={{
                    borderStyle: "solid",
                    padding: 8,
                    borderColor: "#DCDCDC",
                    borderRadius: 200,
                  }}
                >
                  Q {index + 1}
                </div>
                <div
                  style={{ width: 2, backgroundColor: "gray", height: 30 }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 4,
                  marginTop: -24,
                  position: "relative",
                }}
                onMouseEnter={() => setHoverIndex(index)}
                onMouseLeave={() => setHoverIndex(null)}
              >
                {hoverIndex === index && <InfoToolTip />}
                <img src={info} style={{ alignSelf: "flex-end" }} />
                <TextField
                  label={"Page Number(range)"}
                  placeholder="1,2,3...."
                  value={question.pageNumber}
                  onChange={(e) =>
                    handleQuestionChange(index, "pageNumber", e.target.value)
                  }
                />
              </div>
              <TextField
                label={"Max Word Limit(Optional)"}
                placeholder="100"
                value={question.wordLimit}
                type="number"
                style={{ minWidth: 220 }}
                onChange={(e) =>
                  handleQuestionChange(index, "wordLimit", e.target.value)
                }
              />
              <TextField
                label={"Grading Instructions(Optional)"}
                value={question.gradingInstructions}
                style={{ minWidth: 500 }}
                onChange={(e) =>
                  handleQuestionChange(
                    index,
                    "gradingInstructions",
                    e.target.value
                  )
                }
              />
            </div>
          ))} */}
        </div>
        {show && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: 60,
              marginTop: 16,
            }}
          >
            {/* <TextField
              label={"Maximum Word Limit (Optional)"}
              placeholder="Enter the maximum word limit"
              type="number"
              onChange={(e) => setMaxWordLimit(e.target.value)}
              style={{ minWidth: 400 }}
            /> */}
            <div style={{ width: 1180 }}>
            <InputLabel style={{ color: "#464646", fontWeight: "600" }}>Grading Instructions (Optional)</InputLabel>
            <TextField
              // label={"Enter the grading instructions (Optional)"}
              placeholder="Enter the grading instructions"
              value={gradingInstructions}
              onChange={(e) => setGradingInstructions(e.target.value)}
              fullWidth
            />
            </div>
          </div>
        )}

        <Button
          className="login-button"
          onClick={handleGradeButtonClick}
          id="gradeButton"
          style={{
            maxWidth: 200,
            alignSelf: "flex-end",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          Grade the answer
        </Button>
      </div>
    </div>
  );
};

export default Page1;