import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import EssayIcon from "../../assets/images/essays.svg";
import PeopleIcon from "../../assets/images/people.svg";
import SettingIcon from "../../assets/images/setting.svg";
import Logo from "../../assets/images/logo.svg";
import TeacherIcon from "../../assets/images/teacher.png";
import TemplateIcon from "../../assets/images/template.png";
import "./style.css";
import { Link, Outlet, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

const drawerWidth = 210;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ children }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [openLogoutBox, setOpenLogoutBox] = useState(false);
  const { pathname } = useLocation();
  const roleType = JSON.parse(localStorage.getItem("userDetails"));

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    setOpenLogoutBox(false);
    toast.success("Logged out successfully");
    setTimeout(() => {
      localStorage.removeItem("userDetails");
      roleType?.loginData?.role === "Student"
        ? navigate("/studentLogin")
        : roleType?.loginData?.role === "admin"
        ? navigate("/adminLogin")
        : navigate("/");
    }, 500);
  };

  const handleLogoutConfirmClose = () => {
    setOpenLogoutBox(false);
  };

  const handleLogoutConfirmOpen = () => {
    setOpenLogoutBox(true);
  };

  return (
    <>
      <Dialog
        open={openLogoutBox}
        onClose={handleLogoutConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="agree-disagree-btn-div">
          <Button
            variant="contained"
            onClick={handleLogoutConfirmClose}
            className="disagree-btn"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleLogout}
            style={{ textTransform: "capitalize" }}
            autoFocus
          >
            logout
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar className="appBar-content" position="fixed" open={open}>
          <div className="toolbar">
            <div className="icnBtn">
              <IconButton
                className="icn"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
            </div>
          </div>
        </AppBar>
        <Drawer className="drawer-main" variant="permanent" open={open}>
          <div className="sideBar-main">
            <DrawerHeader className="header-main">
              {open && (
                <div
                  className="sidebar-menu"
                  onClick={() => navigate("studentGrade")}
                >
                  <img src={Logo} alt={Logo} style={{ cursor: "pointer" }} />
                </div>
              )}
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <div className="sidebar-content">
              <List className="sidebar-list-content">
                <ListItem
                  className="list-content"
                  disablePadding
                  sx={{ display: "block" }}
                >
                  {roleType?.loginData?.role !== "admin" && (
                    <Link className="link-list" to="/graded/copies">
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          className="menuimg"
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <img src={EssayIcon} alt={EssayIcon} />
                        </ListItemIcon>
                        <ListItemText
                          className={
                            pathname === "/graded/copies"
                              ? "listItem-text-active"
                              : "listItem-text"
                          }
                          primary={"Graded Copies"}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  )}
                  {roleType?.loginData?.role !== "Student" &&
                    roleType?.loginData?.role !== "admin" && (
                      <Link className="link-list" to="/students">
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            className="menuimg"
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            <img src={PeopleIcon} alt={PeopleIcon} />
                          </ListItemIcon>
                          <ListItemText
                            className={
                              pathname === "/students"
                                ? "listItem-text-active"
                                : "listItem-text"
                            }
                            primary={"My Students"}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </Link>
                    )}
                  {roleType?.loginData?.role !== "Student" &&
                    roleType?.loginData?.role !== "admin" && (
                      <Link className="link-list" to="/templates">
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            className="menuimg"
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            <img src={TemplateIcon} alt={TemplateIcon} />
                          </ListItemIcon>
                          <ListItemText
                            className={
                              pathname === "/templates"
                                ? "listItem-text-active"
                                : "listItem-text"
                            }
                            primary={"Templates"}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </Link>
                    )}
                  {roleType?.loginData?.role === "admin" && (
                    <>
                      <Link className="link-list" to="/admin/teachers">
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            className="menuimg"
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            <img src={TeacherIcon} alt={TeacherIcon} />
                          </ListItemIcon>
                          <ListItemText
                            className={
                              pathname === "/admin/teachers"
                                ? "listItem-text-active"
                                : "listItem-text"
                            }
                            primary={"Teachers"}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </Link>
                      <Link className="link-list" to="/admin/students">
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            className="menuimg"
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            <img src={PeopleIcon} alt={PeopleIcon} />
                          </ListItemIcon>
                          <ListItemText
                            className={
                              pathname === "/admin/students"
                                ? "listItem-text-active"
                                : "listItem-text"
                            }
                            primary={"Students"}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </Link>
                    </>
                  )}
                  <Link className="link-list" to="/setting">
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        className="menuimg"
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <img src={SettingIcon} alt={SettingIcon} />
                      </ListItemIcon>
                      <ListItemText
                        className={
                          pathname === "/setting"
                            ? "listItem-text-active"
                            : "listItem-text"
                        }
                        primary={"Settings"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
              </List>
              <List className="logout-ui-div">
                <ListItem
                  className="list-content"
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    onClick={handleLogoutConfirmOpen}
                  >
                    <ListItemIcon
                      className="menuimg"
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <LogoutIcon style={{ fontSize: "20px" }} />
                    </ListItemIcon>
                    <ListItemText
                      className="listItem-text"
                      primary={"Logout"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </div>
          </div>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <div className="outlet">
            <Outlet />
          </div>
        </Box>
      </Box>
    </>
  );
}
